import { MouseEventHandler } from 'react';

export interface CategoriesIconProps {
  className?: string;
  onClick?: MouseEventHandler<SVGElement> | undefined;
}

export default function CategoriesIcon({
  className,
  onClick,
}: CategoriesIconProps) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <g clipPath='url(#clip0_692_11651)'>
        <path
          d='M1.33325 11.3333L7.99992 14.6667L14.6666 11.3333'
          stroke='#1A1919'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1.33325 8L7.99992 11.3333L14.6666 8'
          stroke='#1A1919'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.99992 1.33334L1.33325 4.66668L7.99992 8.00001L14.6666 4.66668L7.99992 1.33334Z'
          stroke='#1A1919'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
}
