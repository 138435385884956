export interface UpgradeIconProps {
  className?: string;
  onClick?: () => void;
}

export default function UpgradeIcon({ className, onClick }: UpgradeIconProps) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M13.333 4.66666H2.66634C1.92996 4.66666 1.33301 5.26361 1.33301 5.99999V12.6667C1.33301 13.403 1.92996 14 2.66634 14H13.333C14.0694 14 14.6663 13.403 14.6663 12.6667V5.99999C14.6663 5.26361 14.0694 4.66666 13.333 4.66666Z'
        stroke='#1A1919'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.6663 14V3.33333C10.6663 2.97971 10.5259 2.64057 10.2758 2.39052C10.0258 2.14048 9.68663 2 9.33301 2H6.66634C6.31272 2 5.97358 2.14048 5.72353 2.39052C5.47348 2.64057 5.33301 2.97971 5.33301 3.33333V14'
        stroke='#1A1919'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
