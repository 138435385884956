import { MouseEventHandler } from 'react';

export interface PaletteIconProps {
  className?: string;
  onClick?: MouseEventHandler<SVGElement> | undefined;
}

export default function PaletteIcon({ className, onClick }: PaletteIconProps) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M9.5 10L14 10C14.5523 10 15 10.4477 15 11L15 14C15 14.5523 14.5523 15 14 15L5 15'
        stroke='#1A1919'
        strokeWidth='1.33'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.625 10.625L10.625 14.6327'
        stroke='#1A1919'
        strokeWidth='1.33'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.99988 7.24499L10.6628 2.58211C11.0533 2.19158 11.6865 2.19158 12.077 2.58211L13.7564 4.26149C14.1469 4.65201 14.1469 5.28517 13.7564 5.6757L5.99988 13.4322'
        stroke='#1A1919'
        strokeWidth='1.33'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.27637 4.96857L11.1103 7.80248'
        stroke='#1A1919'
        strokeWidth='1.33'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='1'
        y='1'
        width='5'
        height='14'
        rx='1'
        stroke='#1A1919'
        strokeWidth='1.33'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 10.625L6 10.625'
        stroke='#1A1919'
        strokeWidth='1.33'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 5.375L6 5.375'
        stroke='#1A1919'
        strokeWidth='1.33'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
