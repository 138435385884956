import { MouseEventHandler } from 'react';

export interface ExportIconProps {
  className?: string;
  onClick?: MouseEventHandler<SVGElement> | undefined;
}

export default function ExportIcon({ className, onClick }: ExportIconProps) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M3.00024 2.66662C3.00024 2.31299 3.14072 1.97386 3.39077 1.72381C3.64082 1.47376 3.97996 1.33328 4.33358 1.33328L8.50064 1.33333L13.1673 5.99999V13.3333C13.1673 13.687 13.0268 14.0261 12.7768 14.2761C12.5267 14.5262 12.1876 14.6667 11.834 14.6667L4.33358 14.6666C3.97996 14.6666 3.64082 14.5261 3.39077 14.2761C3.14072 14.026 3.00024 13.6869 3.00024 13.3333'
        stroke='#1A1919'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 9L3 11L5 9'
        stroke='#1A1919'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 11L3 5'
        stroke='#1A1919'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.66675 2.00003V6.00001L12.9998 6.00003'
        stroke='#1A1919'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
