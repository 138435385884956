import { getIconStrokeColor } from '@/lib/utils';
import { MouseEventHandler } from 'react';

export interface SaveIconProps {
  className?: string;
  onClick?: MouseEventHandler<SVGElement> | undefined;
  disabled?: boolean;
}

export default function SaveIcon({
  className,
  onClick,
  disabled,
}: SaveIconProps) {
  const strokeColor = getIconStrokeColor(disabled);

  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H10.6667L14 5.33333V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14Z'
        stroke={strokeColor}
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.3334 14V8.66666H4.66675V14'
        stroke={strokeColor}
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.66675 2V5.33333H10.0001'
        stroke={strokeColor}
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
