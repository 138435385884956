'use client';

import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import Toolbar from './Toolbar';
import { Fragment, useMemo, useState } from 'react';
import MenuArrowIcon from '../icons/MenuArrow';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  alertModalOptionsAtom,
  categoryEditorVisibleAtom,
  confirmModalOptionsAtom,
  currentUserAtom,
  newPatternModalVisibleAtom,
  openPatternModalVisibleAtom,
  paletteEditorPopoverVisibleAtom,
  planSelectionModalVisibleAtom,
  savePatternModalVisibleAtom,
  selectedToolbarMenuItemAtom,
  transferModalVisibleAtom,
  triggerImageUploaderAtom,
  wizardModalOptionsAtom,
  wizardModalStepsAtom,
} from '@/lib/atoms/general';
import { getToolbarMenuItemIcon } from './ToolbarMenu';
import {
  gridAtom,
  patternAtom,
  patternPositionAtom,
  patternPreviewsAtom,
  patternReferenceImageAtom,
  patternSavedAtom,
  patternSizeAtom,
  patternZoomAtom,
  selectedCategoryAtom,
  selectedStitchTypeAtom,
} from '@/lib/atoms/pattern';
import useIsMobile from '@/lib/hooks/useIsMobile';
import { EMPTY_CATEGORY, WIZARD_STEPS } from '@/lib/constants';
import {
  AnalyticEventNameEnum,
  SubscriptionTypeEnum,
  ToolbarMenuItemEnum,
  UploadImageTriggerTypeEnum,
} from '@/lib/types/general';
import {
  logAnalyticsEvent,
  savePatternInternal,
  notifyPatternSaved,
} from '@/lib/utils';
import { pdf } from '@react-pdf/renderer';
import { PrintPatternDocument } from '../print/PrintPatternDocument';
import { fetchPatterns } from '@/lib/api/pattern';
import { selectedPaletteAtom } from '@/lib/atoms/palette';

const ToolbarContainer = () => {
  const currentUser = useAtomValue(currentUserAtom);
  const isMobile = useIsMobile();
  const selectedToolbarMenuItem = useAtomValue(selectedToolbarMenuItemAtom);
  const [pattern, setPattern] = useAtom(patternAtom);
  const grid = useAtomValue(gridAtom);
  const [isPrintPDFLoading, setPrintPDFIsLoading] = useState<boolean>(false);
  const [isDownloadPDFLoading, setDownloadPDFIsLoading] =
    useState<boolean>(false);

  const setNewPatternModalVisible = useSetAtom(newPatternModalVisibleAtom);
  const setOpenPatternModalVisible = useSetAtom(openPatternModalVisibleAtom);
  const setSavePatternModalVisible = useSetAtom(savePatternModalVisibleAtom);
  const setWizardModalSteps = useSetAtom(wizardModalStepsAtom);
  const setWizardModalOptions = useSetAtom(wizardModalOptionsAtom);

  const setAlertModalOptions = useSetAtom(alertModalOptionsAtom);
  const setTriggerImageUploader = useSetAtom(triggerImageUploaderAtom);

  const selectedStitchType = useAtomValue(selectedStitchTypeAtom);
  const patternSize = useAtomValue(patternSizeAtom);
  const patternZoom = useAtomValue(patternZoomAtom);
  const patternPosition = useAtomValue(patternPositionAtom);
  const [patternSaved, setPatternSaved] = useAtom(patternSavedAtom);
  const setConfirmModalOptions = useSetAtom(confirmModalOptionsAtom);
  const [referenceImage, setPatternReferenceImageAtom] = useAtom(
    patternReferenceImageAtom
  );
  const [patternPreviews, setPatternPreviews] = useAtom(patternPreviewsAtom);
  const [categoryEditorVisible, setCategoryEditorVisible] = useAtom(
    categoryEditorVisibleAtom
  );
  const setSelectedCategory = useSetAtom(selectedCategoryAtom);
  const [paletteEditorPopoverVisible, setPaletteEditorPopoverVisible] = useAtom(
    paletteEditorPopoverVisibleAtom
  );
  const setTransferModalVisible = useSetAtom(transferModalVisibleAtom);
  const setGrid = useSetAtom(gridAtom);
  const selectedPalette = useAtomValue(selectedPaletteAtom);
  const setPlanSelectionModalVisible = useSetAtom(
    planSelectionModalVisibleAtom
  );

  const checkIfPatternIsSaved = () => {
    if (!pattern || !grid) {
      setAlertModalOptions({
        title: 'Save Pattern',
        message: `You must save your pattern before printing.`,
        confirmText: 'OK',
      });
      return false;
    }
    return true;
  };

  const categoriesIsVisible = useMemo(() => {
    return !isMobile && categoryEditorVisible;
  }, [categoryEditorVisible, isMobile]);

  const palettesIsVisible = useMemo(() => {
    return !isMobile && paletteEditorPopoverVisible;
  }, [paletteEditorPopoverVisible, isMobile]);

  const printPDFInternal = async () => {
    if (!(await checkPrintOrDownloadPatternAllowed())) return;
    if (!checkIfPatternIsSaved()) return;

    setPrintPDFIsLoading(true);

    setTimeout(async () => {
      const blob = await pdf(<PrintPatternDocument />).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
      setPrintPDFIsLoading(false);
    }, 300);

    if (pattern) {
      logAnalyticsEvent(AnalyticEventNameEnum.PrintPDFPattern, {
        name: pattern.name,
        stitchType: pattern.stitchTypeId,
        patternSize,
      });
    }
  };

  const downloadPDFInternal = async () => {
    if (!(await checkPrintOrDownloadPatternAllowed())) return;
    if (!checkIfPatternIsSaved()) return;

    setDownloadPDFIsLoading(true);

    setTimeout(async () => {
      const blob = await pdf(<PrintPatternDocument />).toBlob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${pattern?.name}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setTimeout(() => URL.revokeObjectURL(url), 7000);
      setDownloadPDFIsLoading(false);
    }, 300);

    if (pattern) {
      logAnalyticsEvent(AnalyticEventNameEnum.DownloadPDFPattern, {
        name: pattern.name,
        stitchType: pattern.stitchTypeId,
        patternSize,
      });
    }
  };

  const checkSavePatternAllowed = async () => {
    if (
      !currentUser?.subscriptionTypeId ||
      currentUser.subscriptionTypeId === SubscriptionTypeEnum.Free
    ) {
      const patterns = await fetchPatterns('', true, 4);

      if (
        patterns.length >= 3 &&
        (!pattern?.id || !patterns.find((p) => p.id === pattern.id))
      ) {
        setConfirmModalOptions({
          title: 'Upgrade to Premium',
          message: `You have reached the maximum number of saved patterns allowed for your subscription. Please upgrade to a premium subscription to save more patterns.`,
          confirmText: 'Upgrade',
          cancelText: 'Cancel',
          onConfirm: async () => {
            setPlanSelectionModalVisible(true);
          },
        });
        return false;
      }
    }

    return true;
  };

  const checkPrintOrDownloadPatternAllowed = async () => {
    if (
      !currentUser?.subscriptionTypeId ||
      currentUser.subscriptionTypeId === SubscriptionTypeEnum.Free
    ) {
      setConfirmModalOptions({
        title: 'Upgrade to Premium',
        message: `You must upgrade to a premium subscription to print or download patterns.`,
        confirmText: 'Upgrade',
        cancelText: 'Cancel',
        onConfirm: async () => {
          setPlanSelectionModalVisible(true);
        },
      });
      return false;
    }

    return true;
  };

  const save = async () => {
    if (!(await checkSavePatternAllowed())) {
      return;
    }

    if (pattern && !pattern.isTemp) {
      const savedPattern = await savePatternInternal(
        pattern,
        selectedPalette,
        grid,
        patternSize,
        patternPosition,
        patternZoom,
        selectedStitchType,
        referenceImage,
        false
      );

      if (savedPattern) {
        setPattern(savedPattern);
        notifyPatternSaved();
        setPatternSaved(true);
      }

      const newPatternPreviews = { ...patternPreviews };
      newPatternPreviews[pattern.id] = '';
      setPatternPreviews(newPatternPreviews);
    } else {
      setSavePatternModalVisible(true);
    }
  };

  const saveAs = async () => {
    if (!(await checkSavePatternAllowed())) {
      return;
    }

    setSavePatternModalVisible(true);
  };

  const newPattern = () => {
    if (patternSaved) {
      setNewPatternModalVisible(true);
    } else {
      setConfirmModalOptions({
        title: 'Setup new canvas?',
        message: `The current pattern is not saved.\nAre you sure you want to proceed?`,
        confirmText: 'Setup new',
        cancelText: 'Cancel',
        onConfirm: async () => {
          setNewPatternModalVisible(true);
        },
      });
    }
  };

  const showImage = referenceImage?.visible || false;

  const setShowImage = (show: boolean) => {
    if (referenceImage) {
      setPatternReferenceImageAtom({
        ...referenceImage,
        visible: show,
      });
    }
  };

  const onStartTourClick = () => {
    setWizardModalSteps(WIZARD_STEPS);
    setWizardModalOptions({
      index: 0,
      ...WIZARD_STEPS[0],
    });

    logAnalyticsEvent(AnalyticEventNameEnum.StartToor, {});
  };

  const onHelpClick = () => {
    logAnalyticsEvent(AnalyticEventNameEnum.Help, {});
  };

  const onUploadImageClick = () => {
    setTriggerImageUploader(UploadImageTriggerTypeEnum.UploadImage);
    logAnalyticsEvent(AnalyticEventNameEnum.UploadImage, {});
  };

  const onToggleImageVisibilityClick = () => {
    setShowImage(!showImage);

    logAnalyticsEvent(AnalyticEventNameEnum.ToggleImageVisibility, {
      visible: !showImage,
    });
  };

  const toggleCategoriesPopover = () => {
    const newCategoryEditorPopoverVisible = !categoryEditorVisible;
    setCategoryEditorVisible(newCategoryEditorPopoverVisible);
    if (!newCategoryEditorPopoverVisible) {
      setTimeout(() => {
        setSelectedCategory(EMPTY_CATEGORY);
      }, 500);
    }
  };

  const togglePalettesPopover = () => {
    setPaletteEditorPopoverVisible(!paletteEditorPopoverVisible);
  };

  const onTransferClick = () => {
    setTransferModalVisible(true);

    logAnalyticsEvent(AnalyticEventNameEnum.ImageTransferClicked, {
      preEffect: referenceImage?.preEffect,
      rotation: referenceImage?.rotation,
      mirror: referenceImage?.mirror,
      flip: referenceImage?.flip,
    });
  };

  const onClearPatternClick = () => {
    setGrid(
      Array.from({ length: patternSize.rows }).map(() =>
        Array.from({ length: patternSize.cols }).map(() => null)
      )
    );
    logAnalyticsEvent(AnalyticEventNameEnum.ClearPattern, {});
  };

  const onMenuClick = (menuItem: ToolbarMenuItemEnum) => {
    // onClosePopoverToolbar?.();
    switch (menuItem) {
      case ToolbarMenuItemEnum.New:
        newPattern();
        break;
      case ToolbarMenuItemEnum.Open:
        setOpenPatternModalVisible(true);
        break;
      case ToolbarMenuItemEnum.Categories:
        toggleCategoriesPopover();
        break;
      case ToolbarMenuItemEnum.Save:
        if (!patternSaved) {
          save();
        }
        break;
      case ToolbarMenuItemEnum.SaveAs:
        saveAs();
        break;
      case ToolbarMenuItemEnum.Print:
        printPDFInternal();
        break;
      case ToolbarMenuItemEnum.DownloadPDF:
        downloadPDFInternal();
        break;
      case ToolbarMenuItemEnum.UploadImage:
        onUploadImageClick();
        break;
      case ToolbarMenuItemEnum.TransferImage:
        onTransferClick();
        break;
      case ToolbarMenuItemEnum.ClearPattern:
        onClearPatternClick();
        break;
      case ToolbarMenuItemEnum.ShowImage:
        onToggleImageVisibilityClick();
        break;
      case ToolbarMenuItemEnum.HideImage:
        onToggleImageVisibilityClick();
        break;
      case ToolbarMenuItemEnum.Palettes:
        togglePalettesPopover();
        break;
      case ToolbarMenuItemEnum.Guide:
        onStartTourClick();
        break;
      case ToolbarMenuItemEnum.Help:
        onHelpClick();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className='block sm:hidden' id='main-toolbar-mobile'>
        <Popover className='relative'>
          {({}) => (
            <>
              <PopoverButton className='mx-4 my-2 flex h-10 w-16 flex-row items-center gap-1 rounded-lg border border-divider px-1'>
                <div className='rounded bg-background-dark2'>
                  {getToolbarMenuItemIcon(
                    selectedToolbarMenuItem,
                    patternSaved,
                    categoriesIsVisible,
                    palettesIsVisible,
                    (selectedToolbarMenuItem === ToolbarMenuItemEnum.Print &&
                      isPrintPDFLoading) ||
                      (selectedToolbarMenuItem ===
                        ToolbarMenuItemEnum.DownloadPDF &&
                        isDownloadPDFLoading),
                    (e) => {
                      onMenuClick(selectedToolbarMenuItem);
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  )}
                </div>
                <MenuArrowIcon />
              </PopoverButton>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-200'
                enterFrom='opacity-0 translate-y-1'
                enterTo='opacity-100 translate-y-0'
                leave='transition ease-in duration-150'
                leaveFrom='opacity-100 translate-y-0'
                leaveTo='opacity-0 translate-y-1'
              >
                <PopoverPanel className='absolute z-10 mx-4 transform'>
                  {({ close }) => (
                    <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5'>
                      <div className='relative bg-white'>
                        <Toolbar
                          className='flex flex-col sm:hidden'
                          onMenuClick={onMenuClick}
                          onClosePopoverToolbar={close}
                        />
                      </div>
                    </div>
                  )}
                </PopoverPanel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
      <Toolbar
        className='hidden sm:flex'
        onMenuClick={onMenuClick}
        isPrintPDFLoading={isPrintPDFLoading}
        isDownloadPDFLoading={isDownloadPDFLoading}
      />
    </>
  );
};

export default ToolbarContainer;
