import { MouseEventHandler } from 'react';

export interface TransferIconProps {
  className?: string;
  onClick?: MouseEventHandler<SVGElement> | undefined;
}

export default function TransferIcon({
  className,
  onClick,
}: TransferIconProps) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <path
        d='M8.66667 1.33334L2 9.33334H8L7.33333 14.6667L14 6.66667H8L8.66667 1.33334Z'
        stroke='black'
        strokeWidth='1.333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
