/* eslint-disable @next/next/no-img-element */
import Image, { ImageProps } from 'next/image';

export type MyImageProps = ImageProps & {
  isFullScreen?: boolean;
};

const MyImage = (props: MyImageProps) => {
  const { isFullScreen, ...otherProps } = props;
  const { src, alt, className } = props;
  return isFullScreen ? (
    <img src={src as string} alt={alt} className={className} />
  ) : (
    <Image
      {...otherProps}
      alt={props.alt}
      loader={({ src, width: w, quality }) => {
        const q = quality || 75;
        return `/api/general/custom-loader?url=${encodeURIComponent(
          src
        )}&w=${w}&q=${q}`;
      }}
    />
  );
};
export default MyImage;
