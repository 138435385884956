'use client';

import { usePathname } from 'next/navigation';
import { Header } from '../layout/Header';
import { Footer } from '../layout/Footer';
import { cn, shouldRenderHeaderFooter } from '@/lib/utils';

export function ConditionalLayout({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();

  return (
    <>
      {shouldRenderHeaderFooter(pathname) && <Header />}
      <main
        className={cn(
          'flex h-full w-full justify-center',
          shouldRenderHeaderFooter(pathname) ? '' : 'overflow-y-auto'
        )}
      >
        {children}
      </main>
      {shouldRenderHeaderFooter(pathname) && <Footer />}
    </>
  );
}
