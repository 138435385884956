'use client';

import { categoryEditorVisibleAtom } from '@/lib/atoms/general';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { useAtomValue } from 'jotai';
import { Fragment, useMemo } from 'react';
import CategoryEditorComponent from './CategoryEditorComponent';
import useIsMobile from '@/lib/hooks/useIsMobile';
import ToolbarMenu from '../other/ToolbarMenu';
import { ToolbarMenuItemEnum } from '@/lib/types/general';

export interface PaletteListItem {
  id: string;
  name: string;
  colors: string[];
}

export interface CategoryEditorPopoverProps {
  onMenuClick: (menuItem: ToolbarMenuItemEnum) => void;
  onClosePopoverToolbar: () => void;
}

const CategoryEditorPopover = ({
  onMenuClick,
  onClosePopoverToolbar,
}: CategoryEditorPopoverProps) => {
  const isMobile = useIsMobile();
  const categoryEditorVisible = useAtomValue(categoryEditorVisibleAtom);

  const isVisible = useMemo(() => {
    return !isMobile && categoryEditorVisible;
  }, [categoryEditorVisible, isMobile]);

  return (
    <Popover className='relative flex flex-row items-center'>
      <PopoverButton className='w-full focus:outline-none'>
        <ToolbarMenu
          menuItem={ToolbarMenuItemEnum.Categories}
          onClosePopoverToolbar={() => onClosePopoverToolbar?.()}
          onClick={() => onMenuClick(ToolbarMenuItemEnum.Categories)}
          categoriesIsVisible={isVisible}
        />
      </PopoverButton>

      <Transition
        as={Fragment}
        show={isVisible}
        enter='transition ease-out duration-200'
        enterFrom='opacity-0 translate-y-1'
        enterTo='opacity-100 translate-y-0'
        leave='transition ease-in duration-150'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 translate-y-1'
      >
        <PopoverPanel className='absolute top-12 z-10' static>
          <CategoryEditorComponent />
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default CategoryEditorPopover;
