'use client';

import { createCustomerPortalSession } from '@/lib/api/stripe';
import { fetchCurrentUser } from '@/lib/api/user';
import {
  currentUserAtom,
  planSelectionModalVisibleAtom,
} from '@/lib/atoms/general';
import {
  AnalyticEventNameEnum,
  SubscriptionTypeEnum,
} from '@/lib/types/general';
import { useSetAtom } from 'jotai';
import { signOut } from 'next-auth/react';
import { useState } from 'react';
import SpinLoader from '../general/SpinLoader';
import LogoutIcon from '../icons/LogoutIcon';
import UpgradeIcon from '../icons/UpgradeIcon';
import HorizontalDivider from '../other/HorizontalDivider';
import MyImage from './MyImage';
import { logAnalyticsEvent } from '@/lib/utils';

export interface ProfilePopoverProps {
  name: string;
  email: string;
  image: string;
  subscriptionType: SubscriptionTypeEnum;
  onClose?: () => void;
}

const ProfilePopover = ({
  name,
  email,
  image,
  subscriptionType,
  onClose,
}: ProfilePopoverProps) => {
  const setCurrentUser = useSetAtom(currentUserAtom);
  const setPlanSelectionModalVisible = useSetAtom(
    planSelectionModalVisibleAtom
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const redictedCustomerPortal = async () => {
    setIsLoading(true);

    try {
      const newUser = await fetchCurrentUser();
      setCurrentUser(newUser);

      if (newUser) {
        if (newUser.subscriptionTypeId === SubscriptionTypeEnum.Free) {
          setPlanSelectionModalVisible(true);
          onClose?.();
        } else {
          const url = await createCustomerPortalSession(
            newUser.subscriptionCustomerId || ''
          );

          window.location.href = url;

          logAnalyticsEvent(AnalyticEventNameEnum.ManageSubscription, {
            customerId: newUser.subscriptionCustomerId,
            typeId: newUser.subscriptionTypeId,
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='flex w-80 flex-col rounded-lg bg-white shadow-md'>
      <div className='m-4 flex flex-row gap-2'>
        <MyImage
          src={image}
          alt='Profile'
          width={40}
          height={40}
          style={{ objectFit: 'cover' }}
          referrerPolicy='no-referrer'
          className='h-10 w-10 rounded-full object-contain'
        />
        <div className='flex flex-col justify-between'>
          <div className='text-sm font-medium text-primary'>{name}</div>
          <div className='text-xs text-secondary'>{email}</div>
        </div>
      </div>
      <HorizontalDivider />
      {subscriptionType !== SubscriptionTypeEnum.Free ? (
        <>
          <div className='m-4 flex cursor-pointer flex-row items-center justify-between'>
            <div className='flex flex-row items-center gap-2'>
              <UpgradeIcon />
              {subscriptionType === SubscriptionTypeEnum.PremiumYearly ? (
                <div className='text-xs font-medium'>Yearly plan</div>
              ) : (
                <div className='text-xs font-medium'>Monthly plan</div>
              )}
            </div>
            {isLoading ? (
              <SpinLoader size={{ width: 24, height: 24 }} />
            ) : (
              <div
                className='text-xs font-medium text-secondary'
                onClick={redictedCustomerPortal}
              >
                Manage
              </div>
            )}
          </div>
        </>
      ) : (
        <div className='m-4 flex cursor-pointer flex-row items-center justify-between'>
          <div className='flex flex-row items-center gap-2'>
            <UpgradeIcon />
            <div className='text-xs font-medium'>Free plan</div>
          </div>
          {isLoading ? (
            <SpinLoader size={{ width: 24, height: 24 }} />
          ) : (
            <div
              className='text-xs font-medium text-link'
              onClick={redictedCustomerPortal}
            >
              Upgrade
            </div>
          )}
        </div>
      )}
      <HorizontalDivider />
      <div
        className='m-4 flex cursor-pointer flex-row items-center gap-2'
        onClick={() => signOut()}
      >
        <LogoutIcon />
        <div className='text-xs font-medium'>Log out</div>
      </div>
    </div>
  );
};

export default ProfilePopover;
