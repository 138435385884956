import { getSelectableIconClasses } from '@/lib/utils';
import Tooltip from '../general/Tooltip';
import CategoriesIcon from '../icons/CategoriesIcon';
import ExportIcon from '../icons/ExportIcon';
import HelpIcon from '../icons/HelpIcon';
import ImageIcon from '../icons/ImageIcon';
import NewIcon from '../icons/NewIcon';
import OpenIcon from '../icons/OpenIcon';
import PaletteIcon from '../icons/PaletteIcon';
import PrintIcon from '../icons/PrintIcon';
import SaveAsIcon from '../icons/SaveAsIcon';
import SaveIcon from '../icons/SaveIcon';
import BookIcon from '../icons/BookIcon';
import ShowImageIcon from '../icons/ShowImageIcon';
import HideImageIcon from '../icons/HideImageIcon';
import useIsMobile from '@/lib/hooks/useIsMobile';
import { selectedToolbarMenuItemAtom } from '@/lib/atoms/general';
import { useAtom } from 'jotai';
import { MouseEventHandler } from 'react';
import SpinLoader from '../general/SpinLoader';
import TransferIcon from '../icons/TransferIcon';
import { MENU_ITEM_TO_MESSAGE } from '@/lib/constants';
import { ToolbarMenuItemEnum } from '@/lib/types/general';
import ClearIcon from '../icons/ClearIcon';

export const getToolbarMenuItemIcon = (
  itemType: ToolbarMenuItemEnum,
  patternSaved?: boolean,
  categoriesIsVisible?: boolean,
  palettesIsVisible?: boolean,
  isLoading?: boolean,
  onClick?: MouseEventHandler<SVGElement> | undefined
) => {
  if (isLoading) {
    return <SpinLoader size={{ width: 24, height: 24 }} />;
  }

  switch (itemType) {
    case ToolbarMenuItemEnum.New:
      return <NewIcon className='m-2 cursor-pointer' onClick={onClick} />;
    case ToolbarMenuItemEnum.Open:
      return <OpenIcon className='m-2 cursor-pointer' onClick={onClick} />;
    case ToolbarMenuItemEnum.Categories:
      return (
        <div className={getSelectableIconClasses(categoriesIsVisible === true)}>
          <CategoriesIcon className='m-2 cursor-pointer' onClick={onClick} />
        </div>
      );
    case ToolbarMenuItemEnum.Save:
      return (
        <SaveIcon
          className={'m-2 cursor-pointer'}
          onClick={onClick}
          disabled={patternSaved}
        />
      );
    case ToolbarMenuItemEnum.SaveAs:
      return <SaveAsIcon className='m-2 cursor-pointer' onClick={onClick} />;
    case ToolbarMenuItemEnum.Print:
      return <PrintIcon className='m-2 cursor-pointer' onClick={onClick} />;
    case ToolbarMenuItemEnum.DownloadPDF:
      return <ExportIcon className='m-2 cursor-pointer' onClick={onClick} />;
    case ToolbarMenuItemEnum.UploadImage:
      return <ImageIcon className='m-2 cursor-pointer' onClick={onClick} />;
    case ToolbarMenuItemEnum.ShowImage:
      return <ShowImageIcon className='m-2 cursor-pointer' onClick={onClick} />;
    case ToolbarMenuItemEnum.HideImage:
      return <HideImageIcon className='m-2 cursor-pointer' onClick={onClick} />;
    case ToolbarMenuItemEnum.TransferImage:
      return <TransferIcon className='m-2 cursor-pointer' onClick={onClick} />;
    case ToolbarMenuItemEnum.ClearPattern:
      return <ClearIcon className='m-2 cursor-pointer' onClick={onClick} />;
    case ToolbarMenuItemEnum.Palettes:
      return (
        <div className={getSelectableIconClasses(palettesIsVisible === true)}>
          <PaletteIcon className='m-2 cursor-pointer' onClick={onClick} />
        </div>
      );
    case ToolbarMenuItemEnum.Guide:
      return <BookIcon className='m-2 cursor-pointer' onClick={onClick} />;
    case ToolbarMenuItemEnum.Help:
      return <HelpIcon className='m-2 cursor-pointer' onClick={onClick} />;
    default:
      return null;
  }
};

export interface MenuContainerProps {
  menuItem: ToolbarMenuItemEnum;
  onClosePopoverToolbar: () => void;
  onClick?: (menuItem: ToolbarMenuItemEnum) => void;
  patternSaved?: boolean;
  categoriesIsVisible?: boolean;
  palettesIsVisible?: boolean;
  isLoading?: boolean;
}

const ToolbarMenu = ({
  menuItem,
  onClosePopoverToolbar,
  onClick,
  patternSaved,
  categoriesIsVisible,
  palettesIsVisible,
  isLoading,
}: MenuContainerProps) => {
  const isMobile = useIsMobile();
  const message = MENU_ITEM_TO_MESSAGE[menuItem];
  const [selectedToolbarMenuItem, setSelectedToolbarMenuItem] = useAtom(
    selectedToolbarMenuItemAtom
  );

  const selected = selectedToolbarMenuItem === menuItem;

  return (
    <Tooltip
      id={`toolbar-menu-${menuItem}`}
      message={message}
      className='flex flex-row items-center border-b border-divider p-2 sm:border-none sm:p-0'
      onClick={(e) => {
        onClosePopoverToolbar();
        onClick?.(menuItem);
        setSelectedToolbarMenuItem(menuItem);
        if (isMobile) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      {getToolbarMenuItemIcon(
        menuItem,
        patternSaved,
        categoriesIsVisible,
        palettesIsVisible,
        isLoading
      )}
      <div className='flex w-full flex-row items-center justify-between sm:hidden'>
        <div className='flex whitespace-nowrap text-xs font-medium'>
          {message}
        </div>
        {selected && (
          <div className='text-xs font-medium text-secondary'>Selected</div>
        )}
      </div>
    </Tooltip>
  );
};

export default ToolbarMenu;
