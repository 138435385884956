'use client';

import { fetchCurrentTranferSettings, fetchCurrentUser } from '@/lib/api/user';
import {
  currentTransferSettingsAtom,
  currentUserAtom,
} from '@/lib/atoms/general';
import { SubscriptionTypeEnum } from '@/lib/types/general';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { useAtom, useSetAtom } from 'jotai';
import { useSession } from 'next-auth/react';
import { Fragment, useEffect, useState } from 'react';
import SpinLoader from '../general/SpinLoader';
import MenuArrowIcon from '../icons/MenuArrow';
import MyImage from './MyImage';
import ProfilePopover from './ProfilePopover';
import { clarity } from 'react-microsoft-clarity';
import ReactGA from 'react-ga4';
import { usePathname } from 'next/navigation';

const ProfileAvatar = () => {
  const { data: session } = useSession();
  const [currentUser, setCurrentUser] = useAtom(currentUserAtom);
  const setCurrentTranferSettings = useSetAtom(currentTransferSettingsAtom);
  const [profilePopoverVisible, setProfilePopoverVisible] =
    useState<boolean>(false);
  const pathname = usePathname();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_CLARITY_ID) {
      clarity.init(process.env.NEXT_PUBLIC_CLARITY_ID as string);
    }

    if (process.env.NEXT_PUBLIC_GA4_TRACKING_ID) {
      ReactGA.initialize(process.env.NEXT_PUBLIC_GA4_TRACKING_ID as string);
    }
  }, []);

  useEffect(() => {
    if (session) {
      fetchCurrentUser().then((user) => {
        setCurrentUser(user);

        if (user) {
          const userData = {
            id: user.id,
            authUserId: user.authUserId,
            sqliteUserId: user.sqliteUserId,
            subscriptionCustomerId: user.subscriptionCustomerId,
          };

          ReactGA.event({
            category: 'user',
            action: 'logged_in',
            label: user.authUserId,
          });

          if (clarity.hasStarted()) {
            clarity.identify(user.authUserId, userData);
          }
        }
      });
      fetchCurrentTranferSettings().then((settings) => {
        setCurrentTranferSettings(settings);
      });
    }
  }, [session, setCurrentTranferSettings, setCurrentUser]);

  const togglePopover = () => {
    const newProfilePopoverVisible = !profilePopoverVisible;
    setProfilePopoverVisible(newProfilePopoverVisible);
  };

  return (
    <Popover className='relative'>
      <PopoverButton id='profile-button' className='focus:outline-none'>
        <div
          className='mx-4 my-2 flex h-10 w-14 flex-row items-center justify-center gap-1 rounded-lg border border-divider sm:mx-2 sm:h-8 sm:rounded'
          onClick={() => togglePopover()}
        >
          {!session ? (
            pathname !== '/signin' && (
              <SpinLoader size={{ width: 24, height: 24 }} />
            )
          ) : (
            <>
              <MyImage
                src={session?.user?.image || ''}
                alt='Profile'
                width={24}
                height={24}
                style={{ objectFit: 'cover' }}
                referrerPolicy='no-referrer'
                className='h-6 w-6 rounded-full object-contain'
              />
              <MenuArrowIcon className='cursor-pointer' />
            </>
          )}
        </div>
      </PopoverButton>

      <Transition
        show={profilePopoverVisible}
        as={Fragment}
        enter='transition ease-out duration-200'
        enterFrom='opacity-0 translate-y-1'
        enterTo='opacity-100 translate-y-0'
        leave='transition ease-in duration-150'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 translate-y-1'
      >
        <PopoverPanel className='absolute right-2 z-10' static>
          <ProfilePopover
            name={session?.user?.name || ''}
            email={session?.user?.email || ''}
            image={session?.user?.image || ''}
            subscriptionType={
              (currentUser?.subscriptionTypeId as SubscriptionTypeEnum) ||
              SubscriptionTypeEnum.Free
            }
            onClose={() => togglePopover()}
          />
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default ProfileAvatar;
