'use client';

import { wizardModalOptionsAtom } from '@/lib/atoms/general';
import { WizardModalStepEnum } from '@/lib/types/general';
import { useAtomValue } from 'jotai';
import LogoIcon from '../icons/LogoIcon';
import ProfileAvatar from '../profile/ProfileAvatar';
import ToolbarContainer from '../other/ToolbarContainer';

export const Header = () => {
  const wizardModalOptions = useAtomValue(wizardModalOptionsAtom);

  return (
    <div
      className={`flex w-full flex-row items-center justify-between border-b border-b-divider bg-white ${
        wizardModalOptions?.index === WizardModalStepEnum.Menu ||
        wizardModalOptions?.index === WizardModalStepEnum.Profile
          ? 'z-50'
          : 'z-30'
      }`}
    >
      <LogoIcon className='absolute left-[50%] translate-x-[-50%] cursor-pointer' />
      <ToolbarContainer />
      <ProfileAvatar />
    </div>
  );
};
