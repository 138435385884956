import { MouseEventHandler } from 'react';

export interface ClearIconProps {
  className?: string;
  onClick?: MouseEventHandler<SVGElement> | undefined;
}

export default function ClearIcon({ className, onClick }: ClearIconProps) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <g clipPath='url(#clip0_3_2)'>
        <path
          d='M8 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8.00001C14.6667 4.31811 11.6819 1.33334 8 1.33334C4.3181 1.33334 1.33333 4.31811 1.33333 8.00001C1.33333 11.6819 4.3181 14.6667 8 14.6667Z'
          stroke='black'
          strokeWidth='1.33'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10 6L6 10'
          stroke='black'
          strokeWidth='1.33'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6 6L10 10'
          stroke='black'
          strokeWidth='1.33'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
}
