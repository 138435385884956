'use client';

import { useAtomValue } from 'jotai';
import PaletteEditorPopover from '../palette/PaletteEditorPopover';
import CategoryEditorPopover from '../pattern/CategoryEditorPopover';
import ToolbarMenu from './ToolbarMenu';
import VerticalDivider from './VerticalDivider';
import { patternReferenceImageAtom } from '@/lib/atoms/pattern';
import { ToolbarMenuItemEnum } from '@/lib/types/general';

export interface ToolbarProps {
  onMenuClick: (menuItem: ToolbarMenuItemEnum) => void;
  onClosePopoverToolbar?: () => void;
  className?: string;
  isPrintPDFLoading?: boolean;
  isDownloadPDFLoading?: boolean;
}

const Toolbar = ({
  onMenuClick,
  onClosePopoverToolbar,
  className,
  isPrintPDFLoading,
  isDownloadPDFLoading,
}: ToolbarProps) => {
  const referenceImage = useAtomValue(patternReferenceImageAtom);

  const showImage = referenceImage?.visible || false;

  return (
    <div
      id='main-toolbar'
      className={`z-30 flex w-80 flex-col bg-white sm:w-auto sm:flex-row sm:items-center sm:gap-1 ${
        className || ''
      }`}
    >
      <ToolbarMenu
        menuItem={ToolbarMenuItemEnum.New}
        onClosePopoverToolbar={() => onClosePopoverToolbar?.()}
        onClick={onMenuClick}
      />
      <ToolbarMenu
        menuItem={ToolbarMenuItemEnum.Open}
        onClosePopoverToolbar={() => onClosePopoverToolbar?.()}
        onClick={onMenuClick}
      />
      <CategoryEditorPopover
        onMenuClick={onMenuClick}
        onClosePopoverToolbar={() => onClosePopoverToolbar?.()}
      />
      <VerticalDivider className='hidden sm:mx-1 sm:block' />
      <ToolbarMenu
        menuItem={ToolbarMenuItemEnum.Save}
        onClosePopoverToolbar={() => onClosePopoverToolbar?.()}
        onClick={onMenuClick}
      />
      <ToolbarMenu
        menuItem={ToolbarMenuItemEnum.SaveAs}
        onClosePopoverToolbar={() => onClosePopoverToolbar?.()}
        onClick={onMenuClick}
      />
      <VerticalDivider className='hidden sm:mx-1 sm:block' />
      <ToolbarMenu
        menuItem={ToolbarMenuItemEnum.Print}
        onClosePopoverToolbar={() => onClosePopoverToolbar?.()}
        onClick={onMenuClick}
        isLoading={isPrintPDFLoading}
      />
      <ToolbarMenu
        menuItem={ToolbarMenuItemEnum.DownloadPDF}
        onClosePopoverToolbar={() => onClosePopoverToolbar?.()}
        onClick={onMenuClick}
        isLoading={isDownloadPDFLoading}
      />
      <VerticalDivider className='hidden sm:mx-1 sm:block' />
      <ToolbarMenu
        menuItem={ToolbarMenuItemEnum.UploadImage}
        onClosePopoverToolbar={() => onClosePopoverToolbar?.()}
        onClick={onMenuClick}
      />

      {referenceImage && (
        <ToolbarMenu
          menuItem={
            showImage
              ? ToolbarMenuItemEnum.HideImage
              : ToolbarMenuItemEnum.ShowImage
          }
          onClosePopoverToolbar={() => onClosePopoverToolbar?.()}
          onClick={onMenuClick}
        />
      )}

      <ToolbarMenu
        menuItem={ToolbarMenuItemEnum.TransferImage}
        onClosePopoverToolbar={() => onClosePopoverToolbar?.()}
        onClick={onMenuClick}
      />
      <ToolbarMenu
        menuItem={ToolbarMenuItemEnum.ClearPattern}
        onClosePopoverToolbar={() => onClosePopoverToolbar?.()}
        onClick={onMenuClick}
      />
      <VerticalDivider className='hidden sm:mx-1 sm:block' />
      <PaletteEditorPopover
        onClosePopoverToolbar={() => onClosePopoverToolbar?.()}
        onMenuClick={onMenuClick}
      />
      <VerticalDivider className='hidden sm:mx-1 sm:block' />
      <ToolbarMenu
        menuItem={ToolbarMenuItemEnum.Guide}
        onClosePopoverToolbar={() => onClosePopoverToolbar?.()}
        onClick={onMenuClick}
      />
      <ToolbarMenu
        menuItem={ToolbarMenuItemEnum.Help}
        onClosePopoverToolbar={() => onClosePopoverToolbar?.()}
        onClick={onMenuClick}
      />
    </div>
  );
};

export default Toolbar;
